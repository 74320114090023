<template>
    <div class="detail" :class="role === '2' ? 'details' : ''">
        <div class="detail-header">
            <el-breadcrumb separator=">" class="customBreadcrumb">
                <el-breadcrumb-item>数据中心</el-breadcrumb-item>
                <el-breadcrumb-item>班级数据</el-breadcrumb-item>
                <el-breadcrumb-item>{{classData.class_name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button type="primary" @click="goBack">返回</el-button>
        </div>
        <div class="title">
            <img src="../../../assets/images/student/data-center-1.png" alt="">
            <span class="text">新媒体传媒11班</span>
        </div>
        <div class="data-item">
            <div class="class-item">
                <div class="class-table">
                    <div class="table-title">
                        <span class="title-item">学生名称</span>
                        <span class="title-item">训练次数</span>
                        <span class="title-item">模拟次数</span>
                        <span class="title-item">考试次数</span>
                        <span class="title-item">四级电子商务员证书</span>
                        <span class="title-item">三级电子商务师证书</span>
                    </div>
                    <el-scrollbar class="table-body">
                        <div class="body-item" v-for="itemStudent in classData.list">
                            <div class="student-name title-item">
                                <img :src="itemStudent.avatar" alt="">
                                <span class="name">{{itemStudent.name}}</span>
                            </div>
                            <span class="text title-item">{{itemStudent.train_num}}</span>
                            <span class="text title-item">{{itemStudent.mocks_num}}</span>
                            <span class="text title-item">{{itemStudent.exam_num}}</span>
                            <i class="iconfont title-item pass" v-if="itemStudent.is_level_three === 1">&#xe656;</i>
                            <i class="iconfont title-item no-pass" v-if="itemStudent.is_level_three === 0">&#xe659;</i>
                            <i class="iconfont title-item pass" v-if="itemStudent.is_level_four === 1">&#xe656;</i>
                            <i class="iconfont title-item no-pass" v-if="itemStudent.is_level_four === 0">&#xe659;</i>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { teacherdataSingleClassData } from '@/utils/apis.js'

    export default {
        name: "Detail",
        data() {
            return {
                role: '',
                //班级数据
                classData: []
            }
        },
        created() {
            this.role = localStorage.getItem('role');
            this.getClassInfo();
        },
        methods: {
            //获取班级数据
            getClassInfo() {
                let param = {
                    class_id: Number(this.$route.query.c_id)
                }
                teacherdataSingleClassData(param).then(res => {
                    this.classData = res.data;
                    let student = [];
                    if (res.data.data) {
                        res.data.list = [];
                        for (let i in res.data.data) {
                            res.data.list.push(res.data.data[i])
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            //返回
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .details {
        .detail-header {
            margin-top: 20px;
        }
    }
    .detail {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        top: -20px;
        bottom: 0;
        width: 100%;
        height: calc(100% + 20px);
        position: relative;
        background: #F7F8FC;
        .detail-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .title {
            position: relative;
            height: 50px;
            margin-top: 35px;
            img {
                width: 218px;
                height: 50px;
            }
            .text {
                position: absolute;
                left: 0;
                display: inline-block;
                width: 218px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 20px;
                color: #fff;
            }
        }
        .data-item {
            flex: 1;
            height: 1%;
        }
        .class-item {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            height: calc(100% - 20px);
            box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
            border-radius: 6px;
            margin-top: 20px;
            padding: 20px 0 20px 20px;
            box-sizing: border-box;
            .class-top {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
            }
            .class-table {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 1%;
                .table-title {
                    height: 51px;
                    line-height: 51px;
                    background: #E7F6EF;
                    padding: 0 70px;
                    margin-right: 30px;
                }
                .title-item {
                    display: inline-block;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #2DC079;
                    text-align: center;
                    &:nth-child(1) {
                        width: 40%;
                        text-align: left;
                    }
                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                    &:nth-child(6) {
                        width: 15%;
                    }
                }
                .table-body {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    height: 1%;
                    ::v-deep > .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    ::v-deep > .el-scrollbar__bar.is-vertical {
                        width: 10px;
                        .el-scrollbar__thumb {
                            background-color: #2DC079;
                        }
                    }
                    .body-item {
                        display: flex;
                        align-items: center;
                        height: 90px;
                        padding: 0 70px;
                        border-bottom: 1px solid #EEEEEE;
                        margin-right: 30px;
                        .title-item {
                            color: #333;
                            font-weight: 400;
                        }
                        .no-pass {
                            color: #FF0000;
                            font-size: 30px;
                        }
                        .pass {
                            color: #2DC079;
                            font-size: 30px;
                        }
                        .student-name {
                            display: flex;
                            align-items: center;
                            img {
                                width: 42px;
                                height: 42px;
                                border-radius: 50%;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
            .more {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
                margin-top: 27px;
                cursor: pointer;
                i {
                    font-size: 20px;
                }
            }
        }
    }
</style>